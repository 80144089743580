import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import validator from 'validator'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

import '../components/PrimaryButton/primary-button.scss'
import '../scss/pages/contact.scss'

const ContactPage = () => {
  const data = useStaticQuery(query);
  const seo = data.wpPage.seo;

  const [isError, setIsError] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailSentError, setIsEmailSentError] = useState(false);
    const API_PATH = '/api/index.php';
    
    function sendEmail(){
        const nameAndSurname =  document.getElementById('name_surname');
        const email =  document.getElementById('email');
        const message =  document.getElementById('message');
        
        if(validator.isEmpty(nameAndSurname.value) || validator.isEmpty(email.value) || validator.isEmpty(message.value) || !validator.isEmail(email.value)) {
            setIsError(true);
            return;
        } else {
            setIsError(false);
        }

        let formData = {};
        formData.name_surname = nameAndSurname.value;
        formData.email = email.value;
        formData.message = message.value;
        
        fetch(API_PATH, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.code !== 200) setIsEmailSentError(true)
            if (res.code === 200) setIsEmailSent(true)
        })
        .catch((error) => {
            setIsEmailSentError(true)
        });

        nameAndSurname.value = '';
        email.value = '';
        message.value = '';
    }
 
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/o-nas/" />
      <div className="page-top-section">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <p className="small-header text-center mb-0 text-white">Potrzebujesz pomocy?</p>
              <h1 className="header-h1 text-center mt-15 mb-30 text-white">Kontakt</h1>
              <p className="paragraph text-center text-white font-light">Nie znalazłeś tego czego u nas szukałeś, albo potrzebujesz dodatkowych informacji lub chcesz podjąć z nami współpracę? Skorzystaj z dostępnych poniżej form kontaktu, odpowiemy najszybciej jak to będzie możliwe.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="header-h2 black-text-color">Skontaktuj się z nami:</h2>
              <p className="paragraph black-text-color contact-element"><i className="phone-icon" />+48 538 234 827</p>
              <p className="paragraph black-text-color contact-element mb-30"><i className="mail-icon" />kontakt@ubezpieczeniedokredytu.pl</p>
                
              <h2 className="header-h2 black-text-color">Jesteśmy tutaj dla Ciebie</h2>
              <p className="paragraph black-text-color contact-element mb-30"><i className="message-icon" />Od poniedziałku do piątku w godzi. 08:00 - 16:00</p>

              <h2 className="header-h2 black-text-color">Wolisz tradycyjnie?</h2>
              <p className="contact-subtitle">Napisz do nas na adres biura:</p>
              <p className="paragraph black-text-color contact-element mb-30"><i class="nip-icon" />LeadGuru Sp. z o.o.<br />ul. Sycowska 44<br />51-319 Wrocław</p>
            </div>
            <div className="col-md-6">
              {isError && (
                  <p className="error-message">Prosimy prawidłowo wypełnić formularz.</p>
              )}
              {isEmailSent && (
                  <p className="success-message">Formularz został wysłany prawidłowo.</p>
              )}
              {isEmailSentError && (
                  <p className="error-message">Wystąpił błąd. Prosimy spróbować za chwilę.</p>
              )}
              <form id="contact-form">
                  <div className="mb-3">
                      <label for="name_surname" className="form-label">Imię i nazwisko:</label>
                      <input type="text" className="form-control" id="name_surname" />
                  </div>
                  <div className="mb-3">
                      <label for="email" className="form-label">E-mail:</label>
                      <input type="email" className="form-control" id="email" />
                  </div>
                  <div className="mb-3">
                      <label for="message" className="form-label">Treść wiadomości:</label>
                      <textarea id="message" className="form-control"></textarea>
                  </div>
                  <button type="button" className="primary-button" onClick={sendEmail}>Wyślij wiadomość<i className="envelope-icon"></i></button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="map section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="header-h2 mb-30 text-white text-center">Spotkajmy się w naszym biurze!</h2>
              <iframe className="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2502.9188269144265!2d17.104476316097163!3d51.14684797957735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe8f433c91077%3A0x5217d3b2cc44ca09!2sSycowska%2044%2C%2051-319%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1645628436917!5m2!1spl!2spl" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

const query = graphql`
  {
    wpPage(slug: {eq: "kontakt"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`
